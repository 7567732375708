import consumer_trains_product_getOrderInsuranceInfo from '@/lib/data-service/haolv-default/consumer_trains_product_getOrderInsuranceInfo' // 获取保险列表
import consumer_apply_myApplyListV2 from '@/lib/data-service/haolv-default/consumer_apply_myApplyListV2'
import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail' // 获取出差信息
import consumer_trains_product_getAlternativeTrainCheck from '@/lib/data-service/haolv-default/consumer_trains_product_getAlternativeTrainCheck' // 获取车次是否超标
import consumer_department_getDepartmentUsers from '@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers' // 获取全部员工
import consumer_trains_product_getAlternativeTrainNumber from '@/lib/data-service/haolv-default/consumer_trains_product_getAlternativeTrainNumber' // 获取备选车次
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg' // 保存信息
import consumer_trains_order_delOrder from '@/lib/data-service/haolv-default/consumer_trains_order_delOrder' // 删除火车订单
import consumer_flight_order_deleteOrder from '@/lib/data-service/haolv-default/consumer_flight_order_deleteOrder' // 删除机票订单
import consumer_tOdHotelOrderInterim_del from '@/lib/data-service/haolv-default/consumer_tOdHotelOrderInterim_del' // 删除酒店订单
import consumer_trains_order_orderScheduledCheck from '@/lib/data-service/haolv-default/consumer_trains_order_orderScheduledCheck' // 校验超标
import consumer_journey_addOrUpdateOverproof from '@/lib/data-service/haolv-default/consumer_journey_addOrUpdateOverproof' // 创建超标订单
import consumer_web_externalCustomer_getExternalCustomerAll from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_getExternalCustomerAll' // 获取外部客户列表
import consumer_journey_unityCreate from '@/lib/data-service/haolv-default/consumer_journey_unityCreate' // 最终创建订单
import consumer_trains_order_createTrainOrder from '@/lib/data-service/haolv-default/consumer_trains_order_createTrainOrder' // 最终创建订单新街口
import consumer_trains_common_queryRailwayTimetable from '@/lib/data-service/haolv-default/consumer_trains_common_queryRailwayTimetable' // 获取火车票经停信息
import consumer_journey_calculatePrice from '@/lib/data-service/haolv-default/consumer_journey_calculatePrice' // 计算价格
import consumer_tOdHotelOrderInterim_updateCheckInPerson from '@/lib/data-service/haolv-default/consumer_tOdHotelOrderInterim_updateCheckInPerson' // 重新安排酒店房间入住
import consumer_web_city_areaLinkage from '@/lib/data-service/haolv-default/consumer_web_city_areaLinkage' // 获取国家地区
import consumer_trains_order_orderBookingPolling from '@/lib/data-service/haolv-default/consumer_trains_order_orderBookingPolling'

import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import consumer_frequent_contacts_getList from '@/lib/data-service/haolv-default/consumer_frequent_contacts_getList' // 获取常用联系人
import Transfer from '@/page/admin/company-management/staff-management/component/transfer/index.vue'
import CostAttributionList from '@/page/admin/travel-book/reserve/component/costAttributionList/index.vue'
import CheckTravel from '@/component/checkTravel/import_latest_version_module'
import consumer_trains_product_checkTrainsOrderPolicy from '@/lib/data-service/haolv-default/consumer_trains_product_checkTrainsOrderPolicy' // 火车票预定页差标提示
import ReservePopupBox from '@/page/admin/travel-book/home/component/reserve-popup-box/import_latest_version_module'
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import moment from 'moment'
import aes_encrypt from 'nllib/src/common/aes/aes_encrypt';

import consumer_flight_order_flightsStop from '@/lib/common-service/consumer_flight_order_flightsStop'
import consumer_air_ticket_flightRules from '@/lib/common-service/consumer_air_ticket_flightRules'

import EvectionForm from '@/component/evectionForm/import_latest_version_module'   // 因公出行信息显示组件
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.5/index.vue';
import TravelerListForReserve from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-list-for-reserve/import_latest_version_module';
export default {
    data () {
        const validatePhone = (rule, value, callback) => {
            const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/
            if (regExp.test(value)) {
                callback()
            } else {
                callback(new Error('不符合手机号码格式'))
            }
        };
        const validateTravelUserList = (rule, value, callback) => {
            if (value.length === 0) {
                callback(new Error('请选择出行人'))
            } else {
                callback()
            }
        };
        const validateFeeAffiliationId = (rule, value, callback) => {
            if (value === '' || value === null) {
                callback(new Error('请选择费用归属'))
            } else {
                callback()
            }
        };
        const validateTravelCurrent = (rule, value, callback) => {
            if (value === null) {
                callback(new Error('请选择出差事由'))
            } else {
                callback()
            }
        };
        const validateHotelDateArr = (rule, value, callback) => {
            if (value === null || value === '' || value.length === 0) {
                callback(new Error('请选择入住日期'))
            } else {
                callback()
            }
        };
        const validteCheckIn = (rule, value, callback) => {
            callback()
        };
        return {
            loading: false,
            loadingText: '',
            resultLoading: '',
            userInfo: {}, // 个人信息
            evectionNo: '',
            trainOrderNo: '',
            evectionType: 2,
            evectionInfo: {}, // 出差信息合集
            canShowSendMsg: 0, // 是否显示发送短信的勾选 1是显示， 0是不显示
            trainListAll: [], // 火车票行程时刻表
            trainAll:[],
            journeyList: [],
            checkedNoSeat:0,
            radioFront: '1',
            trainNumber:[],     //显示车次信息
            trainNumberAll:[],  //所有车次信息
            trainNumberNew:[],  //新增车次信息
            trainNumberType:[],
            checkList:[],
            typeText:{
                reason: '',
                nameList: '',
                ascription: '',
            },
            trainDataTop:{
                //开始日期
                startDate:null,
                //星期几
                whatDay:null,
                //车次
                trainNo:null,
                //出发站
                fromStationName:null,
                //到达站
                toStationName:null,
                //发车时间
                startTime:null,
                //到达时间
                arriveTime:null,
                //跨越时间
                spanTime:null,
                //座位类型
                seatName:null,
            },
            MaxList: {
                seatName: null,
                seatPrice: null,
            },
            //价格集合
            priceList:{
                trainNo: null, //火车信息
                seatName: null, //火车座位信息
                headCounts: null, //订单总人数
                seatPrice: null, //火车票费用
                robTicketsFee: null, //抢票费单价
                robTicketsAmount: null, //抢票费总价
                serviceChargePrice: null, //服务费单价
                serviceChargeAmount: null, //服务费总价
                trainOrderAmount: null, //合计
            },
            evectionParams: {
                evectionNo: '',
                evectionType: 2,
                explainDesc: '',
                feeAffiliationId: '',
                feeAffiliationName: '', // 费用归属名称
                feeAffiliationType: 1,
                linkman: '',
                linkmanPhone: '',
                reason: '',
                travelUserList: [],
                smsType: 1, // 是否发送短信， 1为是， 0为否

                travelUser: [
                    {
                        id: '',
                        username: '',
                        phone: '',
                        certificateType: 1,
                        certificateValue: '',

                        birthday: '', // 出生日期
                        passengerExpireDate: '', // 证件有效期
                        englishSurnames: '', // 姓氏
                        englishName: '', // 名字（和姓氏配合）
                        staffSex: '', // 性别

                        passengerType: '1',
                        nationality: '', // 国籍

                        choice: 0, //是否保存
                        smsType: 0,

                        uid: '',
                        cid: '',
                        ofcId: '',

                    }
                ], // 因私出行的旅客
            },
            rule: {
                travelUserList: [
                    { validator: validateTravelUserList, trigger: 'blur' }
                ],
                linkman: [
                    { required: true, message: '请输入联系人名称', trigger: 'blur' },
                ],
                linkmanPhone: [
                    { required: true, message: '请输入联系手机号码', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' }
                ],
                feeAffiliationId: [
                    { validator: validateFeeAffiliationId, trigger: 'change' }
                ],
                reason: [
                    { required: true, message: '请输入出差事由', trigger: 'blur' },
                ],
            },
            redirectType: '', // 跳转类型
            currentBusinessTravelList: [], // 当前的出差预定信息
            travelUserList: [], // 出行人合集
            canSelectTravelUserList: [], // 有出差单号情况下一共可选出行人列表
            popSelectTravelUserList: [], // 有出差单号情况下弹窗时可选的出行人列表
            cascadeList: [], // 没有出差单号情况下全部员工列表
            currentActiveList: [], // 没有出差单号情况下选中的员工id合集
            dialogVisible: false, // 有出差预定到选择出差人弹窗
            notTravelDialogVisible: false, // 无出差预定的选择出差人弹窗
            travelUserCheckList: [], // 有出差单号时弹窗中勾选中的出差人合集
            newTravelUser: [],
            pendingTravelUserList: [], // 待定的出行人列表

            trainList: [], // 火车票行程列表
            flightList: [], // 飞机票行程列表
            hotelList: [], // 酒店行程列表

            haveTrainEvection: false,
            haveFlightEvection: false,
            haveHotelEvection: false,

            ticketGrabbingNumber: 0, // 抢票类型的数量

            timeOptionRange: null,

            insuranceProId: '', // 选中的保险
            insuranceList: [], // 保险列表
            insuranceDesc: '', // 保险解释

            businessSeatArr: [
                [{label: 'A', value: '1A', active: false}, {label: 'C', value: '1C', active: false}, {label: 'F', value: '1F', active: false}],
                [{label: 'A', value: '2A', active: false}, {label: 'C', value: '2C', active: false}, {label: 'F', value: '2F', active: false}]
            ], // 特等座选位
            firstSeatArr: [
                [{label: 'A', value: '1A', active: false}, {label: 'C', value: '1C', active: false}, {label: 'D', value: '1D', active: false}, {label: 'F', value: '1F', active: false}],
                [{label: 'A', value: '2A', active: false}, {label: 'C', value: '2C', active: false}, {label: 'D', value: '2D', active: false}, {label: 'F', value: '2F', active: false}]
            ], // 一等座选位
            secondSeatArr: [
                [{label: 'A', value: '1A', active: false}, {label: 'B', value: '1B', active: false}, {label: 'C', value: '1C', active: false}, {label: 'D', value: '1D', active: false}, {label: 'F', value: '1F', active: false}],
                [{label: 'A', value: '2A', active: false}, {label: 'B', value: '2B', active: false}, {label: 'C', value: '2C', active: false}, {label: 'D', value: '2D', active: false}, {label: 'F', value: '2F', active: false}]
            ], // 二等座选位

            productListShow: false, // 备选车次弹窗
            alternativeTrainPopupList: [], // 弹窗显示的内旋车次
            currentTrainIndex: 0, // 当前备选火车票弹窗所属的火车票序号

            dialogNewTrainOrderVisible: false,
            // travelCurrent: null,
            onlySearch: 0,
            dialogNewFlightOrderVisible: false,
            dialogNewHotelOrderVisible: false,
            newTravelParams: {
                travelCurrent: null,
                hotelDateArr: [],
                hotelKeyword: '',
                hotelCheckInDate: '',
                hotelCheckOutDate: ''
            },
            newRule: {
                travelCurrent: [
                    { validator: validateTravelCurrent, trigger: 'change' }
                ],
                hotelDateArr: [
                    { validator: validateHotelDateArr, trigger: 'change' }
                ]
            },
            hotelPickerOptions: {
                disabledDate: this.hotelDisabledDate,
                onPick: this.hotelOnPick
            },
            priceResultOld: null,
            priceResult: {
                flightCalculates: [],
                orderAmountOfCalculationResults:[
                    {
                        headCounts: 0,
                    }
                ]
            },

            dialogCreateOrderVisible: false,

            dialogKnowVisible: false,

            resultMsg: '',
            isHaveOverproof: false,

            ruleList: [],

            hotelUserVisible: false,
            hotelForm: {
                roomNum: 0,
                checkInPerson: []
            },
            hotelRules: {},
            hotelUserList: [],

            dialogToCreateVisible: false, // 出差单出差，是否确定创建订单弹窗

            frequentPassenger: [], // 常用旅客列表
            activeFrequentPassenger: [], // 选中的常用旅客列表
            storageActiveFrequentPassenger: [], // 暂存-选中的常用旅客列表
            passengerDialogVisible: false, // 因私出差-常用旅客选择弹窗
            papersTypeList: [
                // 乘客证件类型：1.身份证，2.护照，3.港澳通行证，4.回乡证，5.台胞证，6.军官证，7.香港身份证，8.台湾通行证，9.户口本，10.其它,11澳門身份證
                {value: 1, label: '身份证'},
                {value: 2, label: '护照'},
                {value: 3, label: '港澳通行证'},
                {value: 4, label: '回乡证'},
                {value: 5, label: '台胞证'},
                {value: 6, label: '军官证'},
                {value: 7, label: '香港身份证'},
                {value: 8, label: '台湾通行证'},
                {value: 9, label: '户口本'},
                {value: 10, label: '其它'},
                {value: 11, label: '澳門身份證'},
                /*{value: '1', label: '身份证'},
                {value: '2', label: '护照'},
                {value: '3', label: '港澳通行证'},
                {value: '4', label: '回乡证'},
                {value: '5', label: '台胞证'},
                {value: '6', label: '军官证'},
                {value: '7', label: '香港身份证'},
                {value: '8', label: '台湾通行证'},
                {value: '9', label: '户口本'},
                {value: '10', label: '其它'},
                {value: '11', label: '澳門身份證'},*/
            ],
            genderList: [
                {value: 1, label: '男性'},
                {value: 2, label: '女性'},
                /*{value: '0', label: '男性'},
                {value: '1', label: '女性'},*/
            ],
            contactDialogVisible: false, // 联系人弹窗
            contactId: '', // 选中的联系人

            maxUser: 9,

            nationalityList: [],

            dialogError2Visible: false,
            errorText: '',

            orderBookingPolling: '',

            maxRoomNum: 9,

            evectionRequired: 0,
            workTravelType: '',  // 因公出差的类型， 1是有选出差单，2是没有
            dialogNewUserVisible: false,
            newUserParams: {
                staffName: '', // 中文名
                phone: '', // 电话号码
                certificates: [
                    {
                        certificateType: 1,
                        certificateValue: ''
                    }
                ],
                behalfCompanyId: '',
                isOutCustomer: 0,
                staffSex: 1,
            },
            newUserRule: {
                staffName: [
                    {required: true, message: '请输入出行人名称', trigger: 'blur'},
                ],
                certificateType: [
                    {required: true, message: '请选择证件类型', trigger: 'change'},
                ],
                certificateValue: [
                    {required: true, message: '请输入证件号码', trigger: 'blur'},
                    {validator: this.validateNewUerCard, trigger: 'blur'}
                ],
                phone: [
                    {required: true, message: '请输入手机号码', trigger: 'blur'},
                    {validator: this.validatePhone, trigger: 'blur'}
                ],
                staffSex: [
                    {required: true, message: '请选择性别', trigger: 'change'},
                ]
            },
            travelToContactDialogVisible: false,
            contactList: [],
            copyDialog: false,
            copyLoading: null,
            copyTextarea: '',
            clickData: false,
            tableData:[
                {
                    standardContent:"高铁/城际：二等座</br>动车：二等座</br>普通车型：硬座",
                    controlRuleText:'321',
                }
            ],
            maxTrain: {
                name:null,
                seatPrice:null,
            },
            addTrainCode:[],
            visible: false,
        }
    },
    components: {
        EvectionForm,
        Transfer,
        CostAttributionList,
        CheckTravel,
        ReservePopupBox,
        TravelerMultipleSelector,
        TravelerListForReserve
    },
    created () {

    },
    mounted () {

    },
    async activated () {
        this.loadingText = '';
        this.redirectType = this.$route.query.redirectType;
        this.evectionNo = this.$route.query.evectionNo;
        this.trainOrderNo = this.$route.query.trainOrderNo;
        this.evectionType = parseInt(this.$route.query.evectionType);
        this.evectionRequired = this.$route.query.evectionRequired === undefined ? 0 : parseInt(this.$route.query.evectionRequired);
        this.workTravelType = this.$route.query.workTravelType === undefined ? '' : parseInt(this.$route.query.workTravelType);

        if (this.$route.query.canShowSendMsg) {
            this.canShowSendMsg = parseInt(this.$route.query.canShowSendMsg)
        } else {
            this.canShowSendMsg = 0
        }

        await this.getUserInfo(); // 获取个人资料
        // await this.getInsuranceInfo(); // 获取保险信息列表
        await this.getEvectionInfo();
        await this.getTrainsOrderPolicy()//火车票预定页差标提示
        await this.getTrainCheck();//获取车次是否超标
        this.getTravelInfoAndUserList(); // 获取当前出差单信息和可选择的出行人列表
        // this.initCheckTravelBox(); // 初始化校验弹框
        this.getAllPrice(); // 获取价格信息


        this.getUsuallyUser(); // 获取常用旅客

        this.getAreaLine(); // 获取国家

        this.$refs['travelerListForReserve'].init({
            situation: '100',
            userIdList: [this.evectionInfo.travellerList[0].userId],
        })
    },
    deactivated() {
        this.orderBookingPolling = ''
    },
    destroyed () {},
    watch: {},
    computed: {},
    filters: {
        filterDate(val) {
            return moment(val).format('YYYY年MM月DD日')
        },
    },
    methods: {
        // consumer_trains_product_getAlternativeTrainCheck
        getTrainCheck(){
            let form = {
                fromStationCode: this.trainDataTop.fromStationCode,
                toStationCode: this.trainDataTop.toStationCode,
                motorcycleType: this.$route.query.motorcycleType,
                trainCode: this.trainDataTop.trainNo,
                trainDate: this.trainDataTop.startDate,
            };

            consumer_trains_product_getAlternativeTrainCheck(form).then(res => {
                //车次、坐席信息
                if (res.resultList.length >= 5) {
                    //车次数量猪狗
                    this.trainNumberAll = res.resultList;
                    this.trainNumber = res.resultList.slice(0,4);
                    this.trainNumberNew = res.resultList.slice(5);
                } else {
                    //车次数量不猪狗
                    this.trainNumber = res.resultList;
                }


            })
        },
        addClick (data) {
            this.checkList = [];
            if (this.addTrainCode.indexOf(data.trainCode) != -1) {
                this.addTrainCode.splice(this.addTrainCode.indexOf(data.trainCode),1);
                data.trainSeatVoList.forEach((item,index) => {
                    this.trainNumberType.splice(this.trainNumberType.indexOf(item),1);
                });

                this.trainAll = [];
            } else {
                if (this.addTrainCode.length >= 4) {
                    this.$message({
                        type: 'warning',
                        message: '最多可以选择4个备选车次'
                    })
                }else {
                    this.addTrainCode.push(data.trainCode);
                    data.trainSeatVoList.forEach((item,index) => {
                        this.trainNumberType.push(item);
                    });
                }
            }

            let yzSeat = [];
            let ywSeat = [];
            let rwSeat = [];
            let wzSeat = [];
            let edzSeat = [];
            let ydzSeat = [];

            let judgeMax = ((num,item,data) => {
                this.trainAll[num] = {
                    name: item.seatName,
                    seatCode: item.seatCode,
                    seatPrice: Math.max.apply(null, data),
                    accord: item.accord,
                };
            });

            this.trainNumberType.forEach((item,index) => {
                switch (item.seatCode) {
                    case 'yz':
                        yzSeat.push(Number(item.seatPrice));
                        judgeMax(0,item,yzSeat);
                        break;
                    case 'yw':
                        ywSeat.push(Number(item.seatPrice));
                        judgeMax(1,item,ywSeat);
                        break;
                    case 'rw':
                        rwSeat.push(Number(item.seatPrice));
                        judgeMax(2,item,rwSeat);
                        break;
                    case 'wz':
                        wzSeat.push(Number(item.seatPrice));
                        judgeMax(3,item,wzSeat);
                        break;
                    case 'edz':
                        edzSeat.push(Number(item.seatPrice));
                        judgeMax(4,item,edzSeat);
                        break;
                    case 'ydz':
                        ydzSeat.push(Number(item.seatPrice));
                        judgeMax(5,item,ydzSeat);
                        break;
                }
            });
        },
        clickOrder(){
            let numTest = [];

            if (this.checkList.length != 0) {
                this.checkList.forEach((item,index) => {
                    numTest.push(this.trainAll[item].seatPrice);
                });

                if (Math.max(...numTest) >= this.priceResult.orderAmountOfCalculationResults[0].seatPrice) {
                    this.trainAll.forEach((item,index) => {
                        if (Math.max(...numTest) == item.seatPrice) {
                            this.MaxList.seatName = item.name;
                            this.MaxList.seatPrice = item.seatPrice;
                        }
                    });

                } else {
                    this.MaxList.seatName = this.priceResultOld.orderAmountOfCalculationResults[0].seatName;
                    this.MaxList.seatPrice = this.priceResultOld.orderAmountOfCalculationResults[0].seatPrice;
                }
            } else {
                this.MaxList.seatName = this.priceResultOld.orderAmountOfCalculationResults[0].seatName;
                this.MaxList.seatPrice = this.priceResultOld.orderAmountOfCalculationResults[0].seatPrice;
            }

            this.saveInfo().then(() => {
                this.getAllPrice();
            });
        },
        getPopoverFalse(){
            this.addTrainCode = [];
            this.trainAll = [];
            this.visible = false;
        },
        getPopoverTrue(){
            if (this.trainNumberAll.length != 0) {
                this.trainNumber = this.trainNumberAll.slice(0,4);
                this.trainNumber = this.trainNumber.concat(this.$refs.multipleTable.selection);
            }
            this.addTrainCode = [];
            this.trainAll = [];
            this.visible = false;
        },
        downClick(){
            this.clickData = !this.clickData
        },
        getTravelerInfo(param) {
            //出行人
            this.evectionParams.travelUserList = param.userList;
            //联系人
            let paramsData = this.$refs['travelerListForReserve'].getTravelerInfo();
            this.evectionParams.linkman = paramsData.linkman;
            this.evectionParams.linkmanPhone = paramsData.linkmanPhone;
            this.evectionParams.linkmanSms = paramsData.linkmanSms;
            this.evectionParams.smsType = paramsData.smsType;
            this.getAllPrice();

            this.trainList[0].chooseSeatArr = [];
            this.trainList[0].seatArr.forEach((item,index) => {
                item.forEach((SItem,SIndex) => {
                    SItem.active = false;
                })
            })
        },
        showThisStationList(val) {
            if (this.trainListAll.length != 0) {
                return
            }
            let params = {
                fromStationCode: val.fromStationCode,
                toStationCode: val.toStationCode,
                trainCode: val.trainNo,
                trainDate: this.$moment(val.startDate).format('YYYY-MM-DD')
            };
            consumer_trains_common_queryRailwayTimetable(params).then(res => {
                let result = res.results;
                let listType = 0;
                for (let i = 0, l = result.length; i < l; i++) {
                    if (result[i].stationName === val.fromStationName) {
                        listType = 1;
                        result[i].listType = 2;
                        continue
                    }
                    if (result[i].stationName === val.toStationName) {
                        listType = 0;
                        result[i].listType = 2;
                        continue
                    }
                    result[i].listType = listType
                }
                this.trainListAll = result;
            })
        },
        submitContactTravelUser() {
            this.travelToContactDialogVisible = false;
            let newArr = JSON.parse(JSON.stringify(this.cascadeList));
            // 将多维数组转成一维数组
            function jsonToArray(nodes) {
                let r = [];
                if (Array.isArray(nodes)) {
                    for (let i = 0, l = nodes.length; i < l; i++) {
                        r.push(nodes[i]); // 取每项数据放入一个新数组
                        if (Array.isArray(nodes[i]["children"]) && nodes[i]["children"].length > 0)
                        // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
                            r = r.concat(jsonToArray(nodes[i]["children"]));
                        delete nodes[i]["children"]
                    }
                }
                return r;
            }
            let afterArr = jsonToArray(newArr);
            afterArr.forEach(value => {
                value.userList.forEach(value1 => {
                    if (this.contactList.indexOf(value1.id) > -1) {
                        this.evectionParams.linkman = value1.name || value1.staffName;
                        this.evectionParams.linkmanPhone = value1.phone
                    }
                })
            })
        },
        submitAddNewUser() {
            this.$refs['newUserForm'].validate((valid) => {
                if (valid) {
                    this.newUserLoading = true;
                    this.loading = true;
                    this.newUserParams.behalfCompanyId = this.companyId;
                    consumer_admin_behalfCustomer_addTraveler(this.newUserParams).then(res => {
                        this.newUserLoading = false;
                        this.loading = false;
                        let newUser = {
                            staffName: this.newUserParams.staffName,
                            name: this.newUserParams.staffName,
                            userId: res.datas,
                            id: res.datas,
                            certificateList: [
                                {
                                    certificateType: this.newUserParams.certificates[0].certificateType,
                                    certificateValue: this.newUserParams.certificates[0].certificateValue
                                }
                            ],
                            certificateType: this.newUserParams.certificates[0].certificateType,
                            certificateValue: this.newUserParams.certificates[0].certificateValue,
                            phone: this.newUserParams.phone,
                            staffSex: this.newUserParams.staffSex
                        };
                        this.evectionParams.travelUserList.push(newUser);
                        this.saveAndReactPrice();
                        this.getTravelInfoAndUserList();
                        this.dialogNewUserVisible = false;
                        // 如果有酒店订单
                        this.reactHotelUser();
                    }).catch(() => {
                        this.newUserLoading = false;
                        this.loading = false;
                    })
                }
            })
        },
        validateNewUerCard(rule, value, callback) {
            if (this.newUserParams.certificates[0].certificateType === 1) {
                const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (!regIdCard.test(value)) {
                    callback(new Error('身份证号填写有误'));
                } else {
                    let birthday = '';
                    if (value.length === 15) {
                        birthday = "19" + value.substr(6, 6);
                    } else if (value.length === 18) {
                        birthday = value.substr(6, 8);
                    }
                    birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
                    let birthdayArr = birthday.split('-');
                    let yearSubmit = parseInt(birthdayArr[0]) !== NaN && parseInt(birthdayArr[0]) >= 1900;
                    let monthSubmit = parseInt(birthdayArr[1]) !== NaN && parseInt(birthdayArr[1]) >= 1 && parseInt(birthdayArr[1]) <= 12;
                    let daySubmit = parseInt(birthdayArr[2]) !== NaN && parseInt(birthdayArr[2]) >= 1 && parseInt(birthdayArr[2]) <= 31;
                    if (!yearSubmit || !monthSubmit || !daySubmit) {
                        callback(new Error("身份证号填写有误"));
                    } else {
                        callback();
                    }
                }
            } else {
                callback();
            }
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        validateCard(rule, value, callback, index) {
            if (this.evectionParams.travelUser[index].certificateType === 1) {
                const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (!regIdCard.test(value)) {
                    callback(new Error('身份证号填写有误'));
                } else {
                    let birthday = '';
                    if (value.length === 15) {
                        birthday = "19" + value.substr(6, 6);
                    } else if (value.length === 18) {
                        birthday = value.substr(6, 8);
                    }
                    birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
                    let birthdayArr = birthday.split('-');
                    let yearSubmit = parseInt(birthdayArr[0]) !== NaN && parseInt(birthdayArr[0]) >= 1900;
                    let monthSubmit = parseInt(birthdayArr[1]) !== NaN && parseInt(birthdayArr[1]) >= 1 && parseInt(birthdayArr[1]) <= 12;
                    let daySubmit = parseInt(birthdayArr[2]) !== NaN && parseInt(birthdayArr[2]) >= 1 && parseInt(birthdayArr[2]) <= 31;
                    if (!yearSubmit || !monthSubmit || !daySubmit) {
                        callback(new Error("身份证号填写有误"));
                    } else {
                        let isHaveRepeat = false;
                        this.evectionParams.travelUser.forEach((value1, index1) => {
                            if (value1.certificateType === 1 && index !== index1 && value1.certificateValue === value) {
                                isHaveRepeat = true
                            }
                        })
                        if (isHaveRepeat) {
                            callback(new Error('有重复身份证号'))
                        } else {
                            callback()
                        }
                    }
                }
            } else {
                callback()
            }
        },
        validatePhone(rule, value, callback) {
            const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/
            if (regExp.test(value) && value.toString().length === 11) {
                callback()
            } else {
                callback(new Error('不符合手机号码格式'))
            }
        },
        validteCheckIn(rule, value, callback) {
            if (value === "" || value === null || value === undefined) {
                callback(new Error("请选择入住人"));
            } else {
                callback();
            }
        },
        getBirthday(index, val) {
            if (this.evectionParams.travelUser[index].certificateType === 1) {
                const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (!regIdCard.test(val)) {
                    return
                }
                let birthday = '';
                if (val.length === 15) {
                    birthday = "19" + val.substr(6, 6);
                } else if (val.length === 18) {
                    birthday = val.substr(6, 8);
                }
                birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
                let birthdayArr = birthday.split('-');
                let yearSubmit = parseInt(birthdayArr[0]) !== NaN && parseInt(birthdayArr[0]) >= 1900;
                let monthSubmit = parseInt(birthdayArr[1]) !== NaN && parseInt(birthdayArr[1]) >= 1 && parseInt(birthdayArr[1]) <= 12;
                let daySubmit = parseInt(birthdayArr[2]) !== NaN && parseInt(birthdayArr[2]) >= 1 && parseInt(birthdayArr[2]) <= 31;
                if (!yearSubmit || !monthSubmit || !daySubmit) {
                    return;
                }
                this.evectionParams.travelUser[index].birthday = birthday
            }
        },
        hotelDisabledDate(time) {
            // 获取选中时间
            let timeOptionRange = this.timeOptionRange;
            // 获取时间范围(30天的毫秒数)
            let secondNum = 30 * 24 * 60 * 60 * 1000;
            if (timeOptionRange) {
                //如果有选中时间 设置超过选中时间后的30天||超过选中前的30天||大于当前时间 不可选
                return time.getTime() > timeOptionRange.getTime() + secondNum || time.getTime() <= timeOptionRange.getTime();
            } else {
                //如果没有选中时间（初始化状态） 设置当前时间后的时间不可选
                return time.getTime() < (Date.now() - 8.64e7);
            }
        },
        hotelOnPick({maxDate, minDate}) {
            // 当选中了第一个日期还没选第二个
            // 只选中一个的时候自动赋值给minDate，当选中第二个后组件自动匹配，将大小日期分别赋值给maxDate、minDate
            if (minDate && !maxDate) {
                this.timeOptionRange = minDate;
            }
            // 如果有maxDate属性，表示2个日期都选择了，则重置timeOptionRange
            if (maxDate) {
                this.timeOptionRange = null;
            }
        },
        async getUserInfo() {
            this.loading = true;
            let [err, res] = await awaitWrap(get_user_info());
            this.loading = false;
            this.userInfo = res.datas;
            //获取登陆人数据填入联系人
            // let paramsData = this.$refs['travelerListForReserve'].getTravelerInfo();
            // paramsData.linkman = this.userInfo.realName;
            // paramsData.linkmanPhone = this.userInfo.phone;
        },
        // 获取出差信息
        async getEvectionInfo() {
            this.loading = true;
            let [err, res] = await awaitWrap(consumer_journey_getEvectionDetail({evectionNo: this.evectionNo, pageType: 1, personType: 2}));
            this.loading = false;
            if (err) {
                return
            }

            //出差事由、出行人员、成本归属
            this.typeText.reason = res.datas.reason;
            let nameTest = [];
            res.datas.travellerList.forEach((item,index) => {
                nameTest.push(item.staffName);
            });
            this.typeText.nameList = nameTest.join(',');
            this.typeText.ascription = res.datas.feeAffiliationName;

            this.evectionInfo = res.datas;
            this.trainDataTop = this.evectionInfo.ticketBookingPageDetailsResponseList[0];
            let userData = this.evectionInfo.travellerList[0];
            this.$refs.travelerListForReserve.form.linkman = userData.staffName;
            this.$refs.travelerListForReserve.form.linkmanPhone = userData.phone;

            this.evectionParams = {
                evectionNo: res.datas.evectionNo,
                evectionType: res.datas.evectionType,
                explainDesc: res.datas.explainDesc,
                feeAffiliationId: res.datas.feeAffiliationId,
                feeAffiliationName: res.datas.feeAffiliationName, // 费用归属名称
                feeAffiliationType: res.datas.feeAffiliationType ? res.datas.feeAffiliationType : 1,
                linkman: res.datas.linkman ? res.datas.linkman : this.userInfo.realName,
                linkmanPhone: res.datas.linkmanPhone ? res.datas.linkmanPhone : this.userInfo.phone,
                reason: res.datas.reason,
                travelUserList: res.datas.travellerList ? res.datas.travellerList : [],
                smsType: res.datas.contactsList && res.datas.contactsList.length > 0 ? res.datas.contactsList[0].smsType : 1, // 是否发送短信， 1为是， 0为否
                travelUser: res.datas.contactsList && res.datas.contactsList.length > 0 ? res.datas.contactsList : [
                    {
                        id: '',
                        username: '',
                        phone: '',
                        certificateType: 1,
                        certificateValue: '',
                        birthday: '', // 出生日期
                        passengerExpireDate: '', // 证件有效期
                        englishSurnames: '', // 姓氏
                        englishName: '', // 名字（和姓氏配合）
                        staffSex: '', // 性别
                        passengerType: '1',
                        nationality: '', // 国籍
                        choice: 0, //是否保存
                        smsType: 1,
                        uid: '',
                        cid: '',
                        ofcId: '',
                    }
                ], // 因私出行的旅客
            };
            this.travelUserList = res.datas.travellerList ? res.datas.travellerList : [];
            this.evectionFormParams = {
                reason: this.evectionParams.reason,
                travelUserList: this.evectionParams.travelUserList,
                feeAffiliationType: this.evectionParams.feeAffiliationType,
                feeAffiliationName: this.evectionParams.feeAffiliationName,
                feeAffiliationId: this.evectionParams.feeAffiliationId
            }

            this.journeyList = res.datas.journeys;
            // 获取火车票行程
            let trainList = res.datas.ticketBookingPageDetailsResponseList;
            let getAlternativeTrainList = []; // 需要获取
            trainList.forEach(value => {
                let seatArr = [];
                if (value.canChooseSeat === 1 && value.numberOfSeats === 5) {
                    [...seatArr] = this.secondSeatArr
                } else if (value.canChooseSeat === 1 && value.numberOfSeats === 4) {
                    [...seatArr] = this.firstSeatArr
                } else if (value.canChooseSeat === 1 && value.numberOfSeats === 3) {
                    [...seatArr] = this.businessSeatArr
                }
                value.seatArr = seatArr;
                value.chooseSeatArr = [];
                if (value.canChooseSeat === 1 && value.chooseSeat !== ''  && value.chooseSeat !== null) {
                    let chooseSeatArr = [];
                    if (value.chooseSeat) {
                        for (let i = 0, l = value.chooseSeat.length; i < l; i+=2) {
                            let arr = value.chooseSeat.slice(i, i+2);
                            chooseSeatArr.push(arr)
                        }
                    }
                    value.chooseSeatArr = chooseSeatArr;
                    value.seatArr.forEach(val => {
                        val.forEach(val1 => {
                            if (value.chooseSeatArr.indexOf(val1.value) > -1) {
                                val1.active = true
                            }
                        })
                    })
                }
                // 保险信息
                value.insuranceProId = value.insuranceProId ? parseInt(value.insuranceProId) : value.insuranceProId
                value.insuranceDesc = '';
                if (value.insuranceProId !== null && value.insuranceProId !== '') {
                    for (let i = 0, l = this.insuranceList.length; i < l; i++) {
                        if (value.insuranceProId === this.insuranceList[i].id) {
                            value.insuranceDesc = this.insuranceList[i].insuranceDesc;
                            value.insurancePrice = this.insuranceList[i].insurancePrice; // 单价
                            value.insuranceName = this.insuranceList[i].insuranceClass.name; // 名字
                        }
                    }
                }

                value.finalPrice = value.seatPrice; // 次行程的最终单价，因为有抢票的情况
                // 获取当前车次的坐席列表
                value.trainSeatList = JSON.parse(value.robTicketNote);
                value.seatList = [];

                // 抢票的情况需要获取备选车次列表
                if (value.orderType === 2) {
                    this.ticketGrabbingNumber++;
                    value.acceptSeatCodesArr = value.acceptSeatCodes ? value.acceptSeatCodes.split(',') : [];
                    let trainParams = {
                        fromStationCode: value.fromStationCode,
                        toStationCode: value.toStationCode,
                        trainCode: value.trainNo,
                        trainDate: value.startDate
                    };
                    let trainItem = consumer_trains_product_getAlternativeTrainNumber(trainParams);
                    getAlternativeTrainList.push(trainItem);

                }
                // consumer_trains_product_getAlternativeTrainNumber
            });
            this.trainList = trainList;

            Promise.all(getAlternativeTrainList).then(data => {
                console.log(data);
                let index = 0;
                let [...finalTrainList] = this.trainList;
                finalTrainList.forEach(value => {
                    if (value.orderType === 2) {
                        let acceptTrainCodesArr = value.acceptTrainCodes ? value.acceptTrainCodes.split(',') : [];
                        let alternativeTrainNumberList = data[index].resultList;
                        alternativeTrainNumberList.forEach(value1 => {
                            if (acceptTrainCodesArr.indexOf(value1.trainCode) > -1) {
                                value1.active = true;
                            } else {
                                value1.active = false;
                            }
                        });
                        value.alternativeTrainNumberList = alternativeTrainNumberList;
                        index++
                    }
                });
                this.trainList = finalTrainList;
                this.trainList.forEach((value, index) => {
                    if (value.orderType === 2) {
                        this.filterSeatList(index);
                    }
                })
            });

            // 获取机票行程
            let flightList = res.datas.flightDetail ? res.datas.flightDetail.orderList : [];
            flightList.forEach(value => {
                value.segmentList.forEach(value1 => {
                    value1.loading = false
                })
            });
            this.flightList = flightList;
            let hotelList = res.datas.todHotelOrderInterimList ? res.datas.todHotelOrderInterimList : [];
            this.hotelList = hotelList
        },
        evectionChange(val) {
            this.evectionFormParams = val
        },
        //火车票预定页差标提示
        async getTrainsOrderPolicy() {
            let res = await consumer_trains_product_checkTrainsOrderPolicy({
                orderNo: this.trainDataTop.orderNo,
            });

            if (res.datas.length == 0) {
                this.tableData = false;
            } else {
                this.tableData[0].standardContent = res.datas[0].standardContent;
                this.tableData[0].controlRuleText = res.datas[0].controlRuleText;
            }

        },
        initCheckTravelBox() {
            this.$refs.checkTravelBox.init({
                checkFun: () => {
                    let flightDate = [];
                    if (this.flightList.length > 0) {
                        this.flightList.forEach(value => {
                            let flightItem = {
                                bottomPrice: '',
                                cabinRank: value.cabinRank,
                                depDate: value.segmentList[0].depDate,
                                discount: value.segmentList[0].discount,
                                price: value.segmentList[0].price,
                                evectionNo: this.evectionNo,
                                depTime: value.segmentList[0].depTime,
                                rqData: value.segmentList[0]
                            };
                            flightDate.push(flightItem)
                        })
                    }
                    let params = {
                        evectionNo: this.evectionNo,
                        userIds: this.newTravelUser,
                        excessRequest: flightDate
                    };
                    this.loading = true;
                    return consumer_trains_order_orderScheduledCheck(params).then(res => {
                        this.loading = false;
                        return res.datas.violationResults || [] // 因无超标人时返回的空数组现在变成null，所以需自传空数组
                    }).catch(() => {
                        this.loading = false;
                        return []
                    })
                },
                reserveSubmitFun: (warningList) => {
                    let overproofApplyList = [];
                    if (warningList.length === 0) {
                        return new Promise(function (resolve) {
                            resolve([])
                        })
                    }
                    warningList.forEach(value => {
                        let item = {
                            businessType: value.businessType,
                            controlRule: value.controlRules,
                            orderNo: value.orderNo,
                            reason: value.reason,
                            reserveContent: value.violationOfReason,
                            standard: value.standard,
                            standardContent: value.seatName,
                            userId: value.userId,
                            userName: value.userRealName
                        };
                        overproofApplyList.push(item)
                    });

                    let params ={
                        evectionNo: this.evectionNo,
                        overproofApplyList
                    };
                    this.loading = true;
                    return consumer_journey_addOrUpdateOverproof(params).then(res => {
                        this.loading = false;
                        return {}
                    }).catch(() => {
                        this.loading = false
                    })
                },
                reserveResultFun: (result) => {
                    if (!result) {
                        return
                    }
                    this.evectionParams.travelUserList = this.pendingTravelUserList;
                    // 清除报错提示
                    this.$refs['orderForm'].clearValidate('travelUserList');
                    this.saveAndReactPrice();

                    // 如果有酒店订单
                    if (this.evectionParams.travelUserList.length > 0) {
                        this.reactHotelUser();
                    }
                }
            })
        },
        // 获取订单所有价格
        async getAllPrice() {
            let params = {
                evectionNo: this.evectionNo,
                headCounts: this.evectionType === 2 ? this.evectionParams.travelUserList.length : this.evectionParams.travelUser.length
            };
            let res = await consumer_journey_calculatePrice(params);
            this.priceResult = res.datas;

            if (this.priceResultOld == null) {
                this.priceResultOld = res.datas;
            }

            //价格集合-new
            this.priceList = {
                trainNo: this.priceResult.orderAmountOfCalculationResults[0].trainNo,
                seatName: this.priceResult.orderAmountOfCalculationResults[0].seatName,
                headCounts: this.priceResult.orderAmountOfCalculationResults[0].headCounts,
                seatPrice: this.priceResult.orderAmountOfCalculationResults[0].seatPrice,
                robTicketsFee: this.priceResult.orderAmountOfCalculationResults[0].robTicketsFee,
                robTicketsAmount: this.priceResult.orderAmountOfCalculationResults[0].robTicketsAmount,
                serviceChargePrice: this.priceResult.orderAmountOfCalculationResults[0].serviceChargePrice,
                serviceChargeAmount: this.priceResult.orderAmountOfCalculationResults[0].serviceChargeAmount,
                trainOrderAmount: this.priceResult.orderAmountOfCalculationResults[0].trainOrderAmount,
            };

        },
        // 获取当前出差单信息和可选择的出行人列表
        getTravelInfoAndUserList() {
            if (this.evectionInfo.evectionType === 1) {
                // 个人出差
                this.getDepartmentUser();
            }  else if (this.evectionInfo.evectionType === 2 && this.workTravelType === 2) {
                this.getDepartmentUser();
            } else {
                // 出差单出差
                let params = {
                    evectionNo: this.evectionNo,
                    approveType: 1,
                };
                consumer_apply_myApplyListV2(params).then(res => {
                    let currentBusinessTravelList = res.datas.list;
                    this.currentBusinessTravelList = currentBusinessTravelList;
                    this.canSelectTravelUserList = res.datas.list[res.datas.list.length - 1].staffList;
                    // 判断是否含有以下行程
                    let journeys = currentBusinessTravelList[res.datas.list.length - 1].journeys;
                    journeys.forEach(value => {
                        if (value.businessType === 1) {
                            this.haveFlightEvection = true;
                        } else if (value.businessType === 2) {
                            this.haveTrainEvection = true;
                        } else {
                            this.haveHotelEvection = true;
                        }
                    })
                })
            }
        },
        // 获取公司所有员工
        async getDepartmentUser() {
            let reduceDataFunc = (data) => {
                data.map((m, i) => {
                    m.children = m.deptList;
                    m.userList = m.staffList;
                    delete m.deptList;
                    delete m.staffList;
                    if (m.userList && m.userList.length) {
                        m.userList.forEach(value => {
                            value.id = value.subUserId;
                            value.name = value.sub;
                        })
                    }
                    if (m.children && m.children.length > 0) {
                        reduceDataFunc(m.children);
                    }
                })
            };
            let result = await consumer_department_getDepartmentUsers({ companyId: this.userInfo.companyId });
            let cascadeList = result.datas;
            reduceDataFunc(cascadeList);
            let [err, customerResult] = await awaitWrap(consumer_web_externalCustomer_getExternalCustomerAll());
            if (err) {
                this.cascadeList = cascadeList
            }
            let customerList = customerResult.datas;
            customerList.forEach(value => {
                value.id = value.userId;
                value.name = value.customerName
            });
            let customerDept = {
                children: [],
                deptId: -1,
                deptLevel: 1,
                deptName: '外部客户',
                deptStatus: 1,
                parentId: 0,
                userList: []
            };
            customerDept.userList = customerList;
            cascadeList.push(customerDept);
            this.cascadeList = cascadeList;
        },
        // 显示选择出行人弹窗
        showSelectUserPopup() {
            this.travelUserCheckList = [];
            if (this.evectionInfo.evectionType === 1) {
                // 个人出差
                this.currentActiveList = [];
                this.evectionParams.travelUserList.forEach(value => {
                    this.currentActiveList.push(value.userId);
                });
                this.notTravelDialogVisible = true;
            } else if (this.evectionInfo.evectionType === 2 && this.workTravelType === 2) {
                this.currentActiveList = [];
                this.evectionParams.travelUserList.forEach(value => {
                    this.currentActiveList.push(value.userId);
                });
                this.notTravelDialogVisible = true;
            } else {
                // 出差单出差
                this.travelUserCheckList = [];
                let afterArr = [];
                let [...canSelectTravelUserList] = this.canSelectTravelUserList;
                for (let i = 0, l = canSelectTravelUserList.length; i < l; i++) {
                    let hasItem = false;
                    canSelectTravelUserList[i].active = false;
                    for (let m = 0, n = this.evectionParams.travelUserList.length; m < n; m++) {
                        if (canSelectTravelUserList[i].userId === this.evectionParams.travelUserList[m].userId) {
                            hasItem = true;
                            break
                        }
                    }
                    if (!hasItem) {
                        afterArr.push(canSelectTravelUserList[i])
                    }
                    if (hasItem) {
                        this.travelUserCheckList.push(canSelectTravelUserList[i].staffId)
                    }
                }
                this.popSelectTravelUserList = afterArr;
                this.dialogVisible = true;
                console.log(this.canSelectTravelUserList);
            }
        },
        // 有出差预定到选择出差人弹窗确认
        submitSelectTravelUser() {
            this.dialogVisible = false;
            if (this.popSelectTravelUserList.length === 0) {
                return
            }
            let travelUserList = [];
            for (let i = 0, l = this.popSelectTravelUserList.length; i < l; i++) {
                if (this.travelUserCheckList.indexOf(this.popSelectTravelUserList[i].staffId) > -1) {
                    travelUserList.push(this.popSelectTravelUserList[i])
                }
            }
            // 获取此次勾选的新出行人
            let newTravelUserList = [];
            travelUserList.forEach(value => {
                newTravelUserList.push(value.userId)
            });
            this.newTravelUser = newTravelUserList;
            this.pendingTravelUserList = [...this.evectionParams.travelUserList, ...travelUserList];
            // 提交校验
            this.$refs.checkTravelBox.run();
        },
        // 无出差预定的选择出差人弹窗确认
        submitTransferTravelUser() {
            let travelUserList = [];
            let newArr = JSON.parse(JSON.stringify(this.cascadeList));
            // 将多维数组转成一维数组
            function jsonToArray(nodes) {
                let r = [];
                if (Array.isArray(nodes)) {
                    for (let i = 0, l = nodes.length; i < l; i++) {
                        r.push(nodes[i]); // 取每项数据放入一个新数组
                        if (Array.isArray(nodes[i]["children"]) && nodes[i]["children"].length > 0)
                            // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
                            r = r.concat(jsonToArray(nodes[i]["children"]));
                        delete nodes[i]["children"]
                    }
                }
                return r;
            }
            let afterArr = jsonToArray(newArr);
            afterArr.forEach(value => {
                value.userList.forEach(value1 => {
                    if (this.currentActiveList.indexOf(value1.id) > -1) {
                        value1.staffId = value1.subStaffId;
                        value1.staffName = value1.subStaffName;
                        value1.userId = value1.userId ? value1.userId : value1.subUserId;
                        travelUserList.push(value1);
                    }
                })
            })
            // 剔除本来存在的用户
            /*let newTravelUser = []
            travelUserList.forEach(value => {
                let isHave = false
                this.evectionParams.travelUserList.forEach(value1 => {
                    if (value1.staffId === value.staffId) {
                        isHave = true
                    }
                })
                if (!isHave) {
                    newTravelUser.push(value.userId)
                }
            })
            this.newTravelUser = newTravelUser*/
            // console.log(travelUserList)
            // this.pendingTravelUserList = travelUserList
            // this.notTravelDialogVisible = false
            // 提交校验
            // this.$refs.checkTravelBox.run()
            this.evectionParams.travelUserList = travelUserList;
            this.notTravelDialogVisible = false;
            // 清除报错提示
            if (travelUserList.length > 0) {
                this.$refs['orderForm'].clearValidate('travelUserList')
            }
            this.saveAndReactPrice();

            // 如果有酒店订单
            if (travelUserList.length > 0) {
                this.reactHotelUser();
            }
        },
        reactHotelUser() {
            if (this.hotelList.length > 0) {
                this.hotelForm.roomNum = this.hotelList[0].roomNum;
                let checkInPerson = [];
                for (let i = 0, l = this.hotelForm.roomNum; i < l; i++) {
                    checkInPerson.push('');
                }
                this.hotelForm.checkInPerson = checkInPerson;
                this.hotelUserList = this.evectionParams.travelUserList.slice();
                this.hotelUserList.forEach(value => {
                    value.disabled = false
                });
                this.hotelUserVisible = true;
            }
        },
        changeHotelUser(index, val) {
            if (val !== '') {
                this.hotelUserList.forEach(value => {
                    if (value.userId === val || this.hotelForm.checkInPerson.indexOf(value.userId) > -1) {
                        value.disabled = true;
                    } else {
                        value.disabled = false;
                    }
                });
                this.$refs.reserveForm.clearValidate(`checkInPerson.${index}`);
            } else {
                this.hotelUserList.forEach(value => {
                    if (value.disabled && this.hotelForm.checkInPerson.indexOf(value.userId) === -1) {
                        value.disabled = false;
                    }
                })
            }
        },
        change_roomNum(newLength) {
            const form = this.hotelForm;
            const oldLength = form.checkInPerson.length;
            if (newLength) {
                if (oldLength) {
                    if (newLength > oldLength) {
                        for (let i = 0; i < newLength - oldLength; i++) {
                            form.checkInPerson.push([]);
                        }
                    } else {
                        form.checkInPerson.splice(newLength, oldLength - newLength);
                        //this.checkInPersonList.splice(newLength, oldLength - newLength);
                    }
                } else {
                    for (let i = 0; i < newLength; i++) {
                        form.checkInPerson.push([]);
                        //this.checkInPersonList.push({ visible: false });
                    }
                }
            } else {
                form.checkInPerson = [];
                //this.checkInPersonList = [];
            }
        },
        async commitHotelReserve() {
            let valid = await this.$refs.reserveForm.validate()
            if (!valid) {
                return
            }
            let params = {
                checkInPerson: this.hotelForm.checkInPerson,
                orderNo: this.hotelList[0].orderNo,
                roomNum: this.hotelForm.roomNum
            }
            let [err, res] = await awaitWrap(consumer_tOdHotelOrderInterim_updateCheckInPerson(params))
            if (err) {
                return
            }
            this.$message({
                type: 'success',
                message: '入住安排保存成功'
            })
            this.hotelUserVisible = false
            // 获取最新的订单详情
            await this.getEvectionInfo()
            this.getAllPrice() // 获取价格信息
            /*this.$refs.reserveForm.validate((valid) => {
                if (valid) {
                    let params = {
                        checkInPerson: this.hotelForm.checkInPerson,
                        orderNo: this.hotelList[0].orderNo
                    }
                    // params.orderNo = this.hotelList[0].orderNo
                    consumer_tOdHotelOrderInterim_updateCheckInPerson(params).then(res => {
                        this.$message({
                            type: 'success',
                            message: '入住安排保存成功'
                        })
                        this.hotelUserVisible = false
                    }).catch(() => {

                    })
                }
            })*/
        },
        chooseSeat(trainIndex, val) {
            // val值不实时
            let acceptSeatCodesArr = this.trainList[trainIndex].acceptSeatCodesArr
            let [...trainList] = this.trainList
            this.trainList = trainList
            // 比较最大价格
            let price = 0
            let [...seatList] = this.trainList[trainIndex].seatList
            if (acceptSeatCodesArr !== undefined && val.length > 0) {
                // 有选中情况
                for (let i = 0, l = acceptSeatCodesArr.length; i < l; i++) {
                    for (let m = 0, n = seatList.length; m < n; m++) {
                        if (acceptSeatCodesArr[i] === seatList[m].seatCode && seatList[m].seatPrice > price) {
                            price = parseFloat(seatList[m].seatPrice)
                            break
                        }
                    }
                }
            }
            let seatPrice = price
            this.trainList[trainIndex].finalPrice = seatPrice > this.trainList[trainIndex].seatPrice ? seatPrice : this.trainList[trainIndex].seatPrice
            this.trainList[trainIndex].acceptSeatCodes = this.trainList[trainIndex].acceptSeatCodesArr.join(',')

            this.saveAndReactPrice()
        },
        // 获取保险信息
        async getInsuranceInfo() {
            this.loading = true
            let [err, res] = await awaitWrap(consumer_trains_product_getOrderInsuranceInfo())
            this.loading = false
            if (err) {
                return
            }
            let insuranceList = res.data
            insuranceList.forEach((value, index) => {
                value.label = `${value.insurancePrice}元/份 ${value.insuranceRemark}`
                /*if (index === 0) {
                    this.insuranceProId = value.id
                    this.insuranceDesc = value.insuranceDesc
                }*/
            })
            this.insuranceList = insuranceList
        },
        changeInsuranceDesc(val, index) {
            this.trainList[index].insuracneFlag = val === '' ? 0 : 1
            if (val === '') {
                this.trainList[index].insuranceDesc = null
                this.trainList[index].insurancePrice = null // 单价
                this.trainList[index].insuranceName = null // 名字
            } else {
                for (let i = 0, l = this.insuranceList.length; i < l; i++) {
                    if (val === this.insuranceList[i].id) {
                        this.trainList[index].insuranceDesc = this.insuranceList[i].insuranceDesc
                        this.trainList[index].insurancePrice = this.insuranceList[i].insurancePrice // 单价
                        this.trainList[index].insuranceName = this.insuranceList[i].insuranceClass.name // 名字
                        break
                    }
                }
            }
            this.saveAndReactPrice()
        },
        delUser(item, index) {
            // if (this.evectionRequired === 1) {
            //     return
            // }
            this.evectionParams.travelUserList.splice(index, 1)
            this.saveAndReactPrice()

            this.trainList.forEach(value => {
                if (value.orderType === 1 && value.canChooseSeat === 1 && value.chooseSeatArr.length > 0) {
                    let item = value.chooseSeatArr[value.chooseSeatArr.length - 1]
                    value.chooseSeatArr.splice(value.chooseSeatArr.length - 1, 1)
                    console.log(value.chooseSeatArr.join(''))
                    value.chooseSeat = value.chooseSeatArr.join('')

                    value.seatArr.forEach(val => {
                        val.forEach(val1 => {
                            if (val1.value === item) {
                                val1.active = false
                            }
                        })
                    })
                }
            })
        },
        chooseTrain(val, index, trainIndex) {
            let [...trainList] = this.trainList
            let [...alternativeTrainNumberList] = this.trainList[trainIndex].alternativeTrainNumberList
            let active = alternativeTrainNumberList[index].active
            let activeNum = 0
            for (let i = 0, l = alternativeTrainNumberList.length; i < l; i++) {
                if (alternativeTrainNumberList[i].active) {
                    activeNum++
                }
            }
            if (activeNum >= 4 && !active) {
                this.$message({
                    type: 'warning',
                    message: '最多可以选择4个备选车次'
                });
                return
            }
            alternativeTrainNumberList[index].active = !active
            trainList[trainIndex].alternativeTrainNumberList = alternativeTrainNumberList
            this.trainList = trainList
            this.setAcceptTrainCodes(trainIndex)

            this.filterSeatList(trainIndex)

        },
        //
        setAcceptTrainCodes(trainIndex) {
            let [...trainList] = this.trainList
            let acceptTrainCodes = []
            let alternativeTrainNumberList = trainList[trainIndex].alternativeTrainNumberList
            alternativeTrainNumberList.forEach(value => {
                if (value.active) {
                    acceptTrainCodes.push(value.trainCode)
                }
            })
            trainList[trainIndex].acceptTrainCodes = acceptTrainCodes.join(',')
            this.trainList = trainList
        },
        // 选中座位
        filterSeatList(trainIndex) {
            let [...trainList] = this.trainList
            let [...seatList] = trainList[trainIndex].trainSeatList
            let seatResult = []

            trainList[trainIndex].alternativeTrainNumberList.forEach(value => {
                if (value.active) {
                    value.trainSeatVoList.forEach(val => {
                        seatList.push(val)
                    })
                }
            })
            // 获取备选车座列表
            for (let i = 0, l = seatList.length; i < l; i++) {
                let hasItem = false
                for (let m = 0, n = seatResult.length; m < n; m++) {
                    if (seatResult[m].seatCode === seatList[i].seatCode) {
                        hasItem = true
                        // 比较价格
                        if (parseFloat(seatResult[m].seatPrice) < parseFloat(seatList[i].seatPrice)) {
                            seatResult[m].seatPrice = seatList[i].seatPrice
                        }
                        break
                    }
                }
                if (!hasItem) {
                    seatResult.push(seatList[i])
                }
            }
            trainList[trainIndex].seatList = seatResult
            this.trainList = trainList

            // 再对比选中的坐席的价格
            this.chooseSeat(trainIndex, trainList[trainIndex].acceptSeatCodesArr)
        },
        // 打开备选车次弹窗
        handleShowMore(trainItem, trainIndex) {
            this.currentTrainIndex = trainIndex
            let [...alternativeTrainNumberList] = trainItem.alternativeTrainNumberList
            this.alternativeTrainPopupList = alternativeTrainNumberList
            this.productListShow = true
            // 遍历一次勾选状态
            this.$nextTick(() => {
                this.alternativeTrainPopupList.forEach((value, index) => {
                    if (index > 4) {
                        this.$refs.multipleTable.toggleRowSelection(value, value.active)
                    }
                })
            })
        },
        delRow({row, rowIndex}) {
            if (rowIndex < 5) {
                return 'display-none'
            } else {
                return ''
            }
        },
        selectTr(selection, row) {
            // 获取当前已选择数
            let alternativeTrainPopupList = this.alternativeTrainPopupList
            let activeNum = 0
            let index = 0
            for (let i = 0, l = alternativeTrainPopupList.length; i < l; i++) {
                if (alternativeTrainPopupList[i].active) {
                    activeNum++
                }
                if (alternativeTrainPopupList[i].trainCode === row.trainCode) {
                    index = i
                }
            }
            if (activeNum >= 4 && !row.active) {
                this.$refs.multipleTable.toggleRowSelection(row);
                this.$message({
                    type: 'warning',
                    message: '最多可以选择4个备选车次'
                })
                return
            }
            alternativeTrainPopupList[index].active = !row.active
            this.alternativeTrainPopupList = alternativeTrainPopupList
        },
        cancelChoose() {
            this.productListShow = false
        },
        submitChoose() {
            this.trainList[this.currentTrainIndex].alternativeTrainNumberList = this.alternativeTrainPopupList
            this.productListShow = false
            this.setAcceptTrainCodes(this.currentTrainIndex)
        },

        handleChooseSeat(index, sIndex, tIndex, val) {
            if (val.active) { // 取消
                this.trainList[tIndex].seatArr[index][sIndex].active = false
                let seatIndex = this.trainList[tIndex].chooseSeatArr.indexOf(val.value)
                this.trainList[tIndex].chooseSeatArr.splice(seatIndex, 1)
                this.trainList[tIndex].chooseSeat = this.trainList[tIndex].chooseSeatArr.join(',')
            } else { // 选中
                if ((this.evectionType === 2 && this.evectionParams.travelUserList.length === 0) || (this.evectionType === 1 && this.evectionParams.travelUser.length === 0)) {
                    this.$message({
                        type: 'warning',
                        message: '请先添加出行人'
                    })
                }
                if ((this.evectionType === 2 && (this.trainList[tIndex].chooseSeatArr.length >= this.evectionParams.travelUserList.length)) || (this.evectionType === 1 && (this.trainList[tIndex].chooseSeatArr.length >= this.evectionParams.travelUser.length))) {
                    return
                }
                this.trainList[tIndex].seatArr[index][sIndex].active = true
                this.trainList[tIndex].chooseSeatArr.push(val.value)
                this.trainList[tIndex].chooseSeat = this.trainList[tIndex].chooseSeatArr.join('')
            }
        },
        // 改车次
        async changeTrain(val, index) {
            await this.saveInfo()
            // 遍历获取当前行程号
            let journeyId = ''
            if (this.evectionParams.evectionType === 2) {
                this.journeyList.forEach(value => {
                    if (value.orderNo === val.orderNo) {
                        journeyId = value.id
                    }
                })
            }
            let queryParams = {
                fromStationCode: val.fromStationCode,
                toStationCode: val.toStationCode,
                time: val.startDate,
                orderNo: val.orderNo,
                redirectType: '002',
                journeyId,
                applyId: this.evectionInfo.evectionApplyId ? this.evectionInfo.evectionApplyId : ''
            }
            const key = 'params';
            const encrypted = aes_encrypt({
                data: JSON.stringify(queryParams),
                key,
            });
            this.$router.push({
                name: 'admin-train-search',
                query: {
                    params: encrypted,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionParams.evectionType,
                }
            })
        },
        // 改航班
        async changeFlight(segment, segmentIndex, flight, fIndex) {
            await this.saveInfo()
            // 遍历获取当前行程号
            let journeyId = ''
            if (this.evectionParams.evectionType === 2) {
                this.journeyList.forEach(value => {
                    if (value.orderNo === flight.journeyNo) {
                        journeyId = value.id
                    }
                })
            }
            let queryParams = {
                fromStationCode: segment.depCityCode,
                fromStationName: segment.depCityName,
                toStationCode: segment.arrCityCode,
                toStationName: segment.arrCityName,
                startDate: segment.depDate,
                flightType: 1,
                applyId: this.evectionInfo.evectionApplyId ? this.evectionInfo.evectionApplyId : '',
                redirectType: '002',
                orderNo: flight.journeyNo,
                journeyId,
            }
            const key = 'params';
            const encrypted = aes_encrypt({
                data: JSON.stringify(queryParams),
                key,
            });
            this.$router.push({
                name: 'admin-flight-search',
                query: {
                    params: encrypted,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionParams.evectionType,
                }
            })
        },
        // 该酒店
        async changeHotel(val, index) {
            await this.saveInfo()
            // 遍历获取当前行程号
            let journeyId = ''
            if (this.evectionParams.evectionType === 2) {
                this.journeyList.forEach(value => {
                    if (value.orderNo === val.id) {
                        journeyId = value.id
                    }
                })
            }
            let queryParams = {
                cityId: val.cityId,
                cityName: val.cityName,
                checkInDate: val.checkInDate,
                checkOutDate: val.checkOutDate,
                redirectType: '002',
                orderNo: val.orderNo,
                journeyId,
                applyId: this.evectionInfo.evectionApplyId ? this.evectionInfo.evectionApplyId : ''
            }
            const key = 'params';
            const encrypted = aes_encrypt({
                data: JSON.stringify(queryParams),
                key,
            });
            this.$router.push({
                name: 'admin-hotel-search',
                query: {
                    params: encrypted,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionParams.evectionType,
                }
            })
        },
        // 删除火车票订单
        delTrainOrder(val, index) {
            this.$confirm('此操作将删除该行程, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                consumer_trains_order_delOrder({orderNo: val.orderNo}).then(res => {
                    this.trainList.splice(index, 1)
                    this.saveAndReactPrice()
                    // 如果是出差单出差
                    if (this.evectionParams.evectionType === 2) {
                        let journeyList = JSON.parse(JSON.stringify(this.journeyList))
                        journeyList.forEach(value => {
                            if (value.orderNo === val.orderNo) {
                                value.orderNo = ''
                            }
                        })
                        this.journeyList = journeyList
                    }
                })
            }).catch(() => {

            })
        },
        // 删除机票订单
        delFlightOrder(segment, segmentIndex, flight, fIndex) {
            this.$confirm('此操作将删除该行程, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                consumer_flight_order_deleteOrder({journeyNo: flight.journeyNo}).then(res => {
                    this.flightList.splice(fIndex, 1)
                    this.saveAndReactPrice()
                    // 如果是出差单出差
                    if (this.evectionParams.evectionType === 2) {
                        let journeyList = JSON.parse(JSON.stringify(this.journeyList))
                        journeyList.forEach(value => {
                            if (value.orderNo === flight.journeyNo) {
                                value.orderNo = ''
                            }
                        })
                        this.journeyList = journeyList
                    }
                })
            }).catch(() => {

            })
        },
        // 删除酒店订单
        delHotelOrder(val, index) {
            this.$confirm('此操作将删除该行程, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                consumer_tOdHotelOrderInterim_del({id: val.id}).then(res => {
                    if (res.datas) {
                        this.hotelList.splice(index, 1)
                        this.saveAndReactPrice()
                        // 如果是出差单出差
                        if (this.evectionParams.evectionType === 2) {
                            let journeyList = JSON.parse(JSON.stringify(this.journeyList))
                            journeyList.forEach(value => {
                                if (value.orderNo === val.orderNo) {
                                    value.orderNo = ''
                                }
                            })
                            this.journeyList = journeyList
                        }
                    } else {
                        this.$message({
                            type: 'warning',
                            message: res.msg
                        })
                    }
                })
            }).catch(() => {

            })
        },
        // 保存信息
        async saveInfo() {
            let params = Object.assign({}, this.evectionParams)
            let userIdList = []
            this.evectionParams.travelUserList.forEach(value => {
                userIdList.push(value.userId)
            })
            params.userIdList = userIdList
            params.ticketRequests = this.trainList
            let contactsVO = []
            params.travelUser.forEach(value => {
                value.evectionNo = params.evectionNo
                value.smsType = this.evectionParams.smsType
                if (value.certificateType === '1' && value.nationality === '') { // 如果是身份证且没有选国籍，自动补上70007
                    value.nationality = 70007
                }

                // 如果勾选了保存成为常用旅客，当填写不完整，就不给暂存
                if (this.isCanSveUser(value)) {
                    contactsVO.push(value)
                }
            });

            params.contactsVO = contactsVO;
            if (this.MaxList.seatPrice == null) {
                params.ticketRequests[0].alternativeSeatPrice = this.priceList.seatPrice;
            } else {
                params.ticketRequests[0].alternativeSeatPrice = this.MaxList.seatPrice;
            }
            params = {...params, ...this.evectionFormParams}

            let [err, res] = await awaitWrap(consumer_journey_saveOrderMsg(params));
            if (err) {
                return false
            }
            return res
        },
        // 判断出行人是否填写完整
        isCanSveUser(value) {
            if (value.choice === 0) {
                return true
            }
            const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/;
            if (value.username === '' || !regExp.test(value.phone) || value.phone.toString().length !== 11) {
                return false
            }

            return true

        },
        // 新增火车票
        async toSearchTrain() {
            if (this.evectionParams.evectionType === 2 && !this.haveTrainEvection) {
                return
            }
            let continueToTrain = await this.saveInfo();
            if (!continueToTrain) {
                return
            }
            if (this.evectionInfo.evectionType === 2) {
                // 出差单预定
                this.newTravelParams.travelCurrent = null;
                let current = JSON.parse(JSON.stringify(this.currentBusinessTravelList[0]));
                for (let i = 0, l = current.journeys.length; i < l; i++) {

                    if (current.journeys[i].businessType === 2) {
                        current.active = current.journeys[i]
                    }
                }
                this.newTravelParams.travelCurrent = current
                this.onlySearch = 0
                this.dialogNewTrainOrderVisible = true
            } else {
                // 个人预定
                let fromStationCode = '';
                let toStationCode = '';
                let startDate = '';
                if (this.trainList.length > 0) {
                    this.trainList.forEach(value => {
                        fromStationCode = value.fromStationCode;
                        toStationCode = value.toStationCode;
                        startDate: value.startDate
                    })
                }
                let queryParams = {
                    fromStationCode: fromStationCode,
                    toStationCode: toStationCode,
                    time: startDate,
                    redirectType: '001',
                };
                const key = 'params';
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.$router.push({
                    name: 'admin-train-search',
                    query: {
                        params: encrypted,
                        evectionNo: this.evectionNo,
                        evectionType: this.evectionParams.evectionType
                    }
                })
            }
        },
        // 新增机票
        async toSearchFlight() {
            if (this.evectionParams.evectionType === 2 && !this.haveFlightEvection) {
                return
            }
            let continueToFlight = await this.saveInfo()
            if (!continueToFlight) {
                return
            }
            if (this.evectionInfo.evectionType === 2) {
                // 出差单预定
                this.newTravelParams.travelCurrent = null
                let current = JSON.parse(JSON.stringify(this.currentBusinessTravelList[0]))
                for (let i = 0, l = current.journeys.length; i < l; i++) {

                    if (current.journeys[i].businessType === 1) {
                        current.active = current.journeys[i]
                    }
                }
                this.newTravelParams.travelCurrent = current
                this.dialogNewFlightOrderVisible = true
            } else {
                // 个人预定
                let fromStationCode = ''
                let fromStationName = ''
                let toStationCode = ''
                let toStationName = ''
                let startDate = ''
                let endDate = ''
                this.flightList.forEach(value => {
                    fromStationCode = value.segmentList[0].depCityCode
                    fromStationName = value.segmentList[0].depCityName
                    toStationCode = value.segmentList[0].arrCityCode
                    toStationName = value.segmentList[0].arrCityName
                    startDate = value.segmentList[0].depDate
                })
                let queryParams = {
                    fromStationCode: fromStationCode,
                    fromStationName: fromStationName,
                    toStationCode: toStationCode,
                    toStationName: toStationName,
                    startDate: startDate,
                    endDate: endDate,
                    flightType: 1,
                    redirectType: '001',
                }
                const key = 'params';
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.$router.push({
                    name: 'admin-flight-search',
                    query: {
                        params: encrypted,
                        evectionNo: this.evectionNo,
                        evectionType: this.evectionParams.evectionType
                    }
                })
            }
        },
        // 新增酒店
        async toSearchHotel() {
            if (this.evectionParams.evectionType === 2 && !this.haveHotelEvection) {
                return
            }
            let continueToHotel = await this.saveInfo()
            if (!continueToHotel) {
                return
            }
            if (this.evectionInfo.evectionType === 2) {
                // 出差单预定
                this.newTravelParams.travelCurrent = null
                let current = JSON.parse(JSON.stringify(this.currentBusinessTravelList[0]))
                for (let i = 0, l = current.journeys.length; i < l; i++) {

                    if (current.journeys[i].businessType === 3) {
                        current.active = current.journeys[i]
                    }
                }
                this.newTravelParams.travelCurrent = current
                this.dialogNewHotelOrderVisible = true
            } else {
                // 个人预定
                let cityId = ''
                let cityName = ''
                let checkInDate = ''
                let checkOutDate = ''
                let hotelName = ''
                this.hotelList.forEach(value => {
                    cityId = value.cityId
                    cityName = value.cityName
                    checkInDate = value.checkInDate
                    checkOutDate = value.checkOutDate
                    hotelName = value.hotelName
                })
                let queryParams = {
                    cityId: cityId,
                    cityName: cityName,
                    checkInDate: checkInDate,
                    checkOutDate: checkOutDate,
                    keyword: hotelName,
                    hotelStar: '',
                    redirectType: '001',
                }
                const key = 'params';
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.$router.push({
                    name: 'admin-hotel-search',
                    query: {
                        params: encrypted,
                        evectionNo: this.evectionNo,
                        evectionType: this.evectionParams.evectionType
                    }
                })
            }
        },
        submitSearchNewTravelToTrain() {
            /*if (this.newTravelParams.travelCurrent === null) {
                this.$message({
                    type: 'warning',
                    message: '请先选择出差事由'
                })
                return
            }*/
            this.$refs['trainForm'].validate((valid) => {
                if (valid) {
                    let queryParams = {
                        redirectType: '001',
                        time: this.newTravelParams.travelCurrent.active.departTime,
                        fromStationCode: this.newTravelParams.travelCurrent.active.departCityId,
                        toStationCode: this.newTravelParams.travelCurrent.active.toCityId,
                        onlySearch: this.onlySearch,
                    }
                    const key = 'params';
                    const encrypted = aes_encrypt({
                        data: JSON.stringify(queryParams),
                        key,
                    });
                    this.dialogNewTrainOrderVisible = false
                    this.$router.push({
                        name: 'admin-train-search',
                        query: {
                            params: encrypted,
                            evectionNo: this.evectionNo,
                            evectionType: this.evectionParams.evectionType
                        }
                    })
                }
            })

        },
        submitSearchNewTravelToFlight() {
            /*if (this.newTravelParams.travelCurrent === null) {
                this.$message({
                    type: 'warning',
                    message: '请先选择出差事由'
                })
                return
            }*/
            this.$refs['flightForm'].validate((valid) => {
                if (valid) {
                    this.dialogNewFlightOrderVisible = false
                    let queryParams = {
                        redirectType: '001',
                        startDate: this.newTravelParams.travelCurrent.active.departTime,
                        endDate: this.newTravelParams.travelCurrent.active.returnTime,
                        fromStationCode: this.newTravelParams.travelCurrent.active.departCityId,
                        fromStationName: this.newTravelParams.travelCurrent.active.departCityName,
                        toStationCode: this.newTravelParams.travelCurrent.active.toCityId,
                        toStationName: this.newTravelParams.travelCurrent.active.toCityName,
                        flightType: 1,
                    }
                    const key = 'params';
                    const encrypted = aes_encrypt({
                        data: JSON.stringify(queryParams),
                        key,
                    });
                    this.$router.push({
                        name: 'admin-flight-search',
                        query: {
                            params: encrypted,
                            evectionNo: this.evectionNo,
                            evectionType: this.evectionParams.evectionType
                        }
                    })
                }
            })
        },
        submitSearchNewTravelToHotel() {
            this.$refs['hotelForm'].validate((valid) => {
                if (valid) {
                    this.dialogNewHotelOrderVisible = false
                    let queryParams = {
                        redirectType: '001',
                        cityId: this.newTravelParams.travelCurrent.active.toCityId,
                        cityName: this.newTravelParams.travelCurrent.active.toCityName,
                        checkInDate: this.newTravelParams.hotelCheckInDate,
                        checkOutDate: this.newTravelParams.hotelCheckOutDate,
                        keyword: this.newTravelParams.hotelKeyword,
                        hotelStar: '',
                    }
                    const key = 'params';
                    const encrypted = aes_encrypt({
                        data: JSON.stringify(queryParams),
                        key,
                    });
                    this.$router.push({
                        name: 'admin-hotel-search',
                        query: {
                            params: encrypted,
                            evectionNo: this.evectionNo,
                            evectionType: this.evectionParams.evectionType
                        }
                    })
                }
            })
        },
        changeHotelDate(val) {
            if (val === '' || val === null || val.length === 0) {
                this.newTravelParams.hotelCheckInDate = ''
                this.newTravelParams.hotelCheckOutDate = ''
            } else {
                this.newTravelParams.hotelCheckInDate = val[0]
                this.newTravelParams.hotelCheckOutDate = val[1]
            }
        },
        blurHotelDate() {
            this.timeOptionRange = null
        },
        // 最终创建订单
        async createOrder() {
            let valid = await this.$refs.evectionForm.checkForm()
            if (!valid) {
                this.loading = false
                return
            }
            let params = Object.assign({}, this.evectionParams);
            let userIdList = [];
            let trainCode = [];
            this.evectionParams.travelUserList.forEach(value => {
                userIdList.push(value.id || value.userId)
            });
            params.userIdList = userIdList;
            params.ticketRequests = this.trainList;
            params.userList = params.travelUserList;
            //抢票备选车次
            params.ticketRequests[0].acceptTrainCodes = this.addTrainCode.join(',');
            //抢票备选坐席
            this.checkList.forEach((item,index) => {
                trainCode[index] = this.trainAll[item].seatCode;
            });
            params.ticketRequests[0].acceptSeatCodes = trainCode.join(',');
            //联系人(调用组件方法获取组件钟联系人的信息)
            let paramsData = this.$refs['travelerListForReserve'].getTravelerInfo();
            this.evectionParams.linkman = paramsData.linkman;
            this.evectionParams.linkmanPhone = paramsData.linkmanPhone;

            if (this.flightList.length === 0 && this.trainList.length === 0 && this.hotelList.length === 0) {
                this.$message({
                    type: 'warning',
                    message: '请先添加行程'
                });
                return
            }
            //出行人、选择、联系人判断
            if (paramsData.userList.length == 0) {
                this.$message({
                    type: 'warning',
                    message: '请先添加出行人'
                });
                return
            } else if (this.evectionParams.linkman == '' || this.evectionParams.linkmanPhone == '') {
                this.$message({
                    type: 'warning',
                    message: '请先添加联系人'
                });
                return
            }else if (trainCode.length == 0 && this.addTrainCode.length != 0) {
                this.$message({
                    type: 'warning',
                    message: '请先选择坐席'
                });
                return
            }


            // let loading
            this.resultLoading = this.$loading({
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            let saveResult = await this.saveInfo()
            if (!saveResult) {
                this.resultLoading.close()
                return
            }
            // 重新保存酒店出行人，当前有酒店的情况（当前默认只能订一间酒店）
            if (this.hotelList.length > 0) {
                let params = {
                    checkInPerson: [],
                    orderNo: ''
                }
                if (this.evectionType === 2) {
                    this.evectionParams.travelUserList.forEach((value, index) => {
                        params.checkInPerson.push(value.userId)
                    })
                } else {
                    saveResult.datas.forEach((value, index) => {
                        let roomNum = this.hotelList[0].roomNum
                        if (roomNum > index) {
                            params.checkInPerson.push(value)
                        }
                    })
                }

                params.orderNo = this.hotelList[0].orderNo;
                let [hotelErr, hotelRes] = await awaitWrap(consumer_tOdHotelOrderInterim_updateCheckInPerson(params))
                if (hotelErr) {
                    this.resultLoading.close();
                    return
                }
            }

            /*if (this.evectionInfo.evectionType === 2) {
                // 出差单出差
                this.dialogCreateOrderVisible = true
                return
            }*/
            // 因私出差

            let [err, result] = await awaitWrap(consumer_trains_order_createTrainOrder(
                {
                    evectionNo: this.evectionNo,
                    trainOrderNo: this.trainOrderNo,
                    pageType: 1
                }
                ))
            if (err) {
                this.resultLoading.close()
                let canSubmit = true
                let errorCode = err.data.code;
                let orderResultList = err.data.datas.orderResultList
                this.errorText = err.data.msg;
                this.dialogError2Visible = true
                if (!orderResultList) {
                    return
                }
                orderResultList.forEach(value => {
                    if (!value.isSuccess) {
                        canSubmit = false
                        let [...trainList] = this.trainList
                        trainList.forEach(value1 => {
                            if (value1.orderNo === value.orderNo) {
                                value1.showError = true
                                value1.errorReason = value.desc
                            }
                        })
                        this.trainList = trainList
                    }
                })
                return
            }
            let orderResult = result.datas
            let canSubmit = true
            orderResult.orderResultList.forEach(value => {
                if (!value.isSuccess) {
                    canSubmit = false
                    let [...trainList] = this.trainList
                    trainList.forEach(value1 => {
                        if (value1.orderNo === value.orderNo) {
                            value1.showError = true
                            value1.errorReason = value.desc
                        }
                    })
                    this.trainList = trainList
                }
            })
            //loading.close()
            if (canSubmit) {
                // 如果有火车票
                let _this = this
                this.orderBookingPolling = function (trainOrderList, orderResult) {
                    consumer_trains_order_orderBookingPolling({orderNos: trainOrderList}).then(result => {
                        let resultSuccessfulList = []
                        result.orderBookingPollingResults.forEach(value => {
                            resultSuccessfulList.push(value.successful)
                        })
                        if (resultSuccessfulList.indexOf(0) >= 0) {
                            // 继续轮询
                            setTimeout(() => {
                                if (this.orderBookingPolling === '') {
                                    return
                                }
                                this.orderBookingPolling(trainOrderList, orderResult)
                            }, 500)
                        } else {
                            // 成功
                            this.resultLoading.close()
                            this.orderResult(orderResult)
                        }
                    }, error => {
                        // 失败
                        this.resultLoading.close()
                        let errorCode = error.data.code;
                        let orderResultList = error.data.datas.orderBookingPollingResults
                        let [...trainList] = this.trainList
                        orderResultList.forEach(value => {
                            trainList.forEach(value1 => {
                                if (value1.orderNo === value.orderNo && value.successful === 2) {
                                    value1.showError = true
                                    value1.errorReason = value.errorMessage
                                }
                            })
                        })
                        this.trainList = trainList
                        this.errorText = error.data.msg;
                        this.dialogError2Visible = true
                    }).catch(() => {
                        this.resultLoading.close()
                    })
                }
                // 如果有火车票
                if (this.trainList.length > 0) {
                    let trainOrderList = []
                    this.trainList.forEach(value => {
                        trainOrderList.push(value.orderNo)
                    })
                    this.resultLoading.close()
                    this.resultLoading = this.$loading({
                        lock: true,
                        spinner: 'el-icon-loading',
                        text: '火车票正在占座中，请耐心等待几分钟...',
                        background: 'rgba(255, 255, 255, 0.7)'
                    });
                    this.orderBookingPolling(trainOrderList, result)
                } else {
                    this.resultLoading.close()
                    this.orderResult(result)
                }
            } else {
                this.resultLoading.close()
                this.$message({
                    type: 'warning',
                    message: '订票失败，请重新选择行程'
                })
            }
        },
        orderResult(result) {
            if (result.datas.creditExtension || this.evectionInfo.evectionType === 1) {
                // 走收银台
                this.$router.push({
                    name: 'admin-travel-payment',
                    query: {
                        evectionNo: this.evectionNo
                    }
                })
            } else {
                this.resultMsg = result.msg;
                this.isHaveOverproof = result.datas.isHaveOverproof;
                this.dialogCreateOrderVisible = true
            }
        },
        // 出差单出差-取消支付
        cancelContinueSubmit() {
            this.dialogToCreateVisible = false
        },
        // 出查单出差-继续支付
        async continueSubmit() {
            this.dialogToCreateVisible = false
            let loading
            loading = this.$loading({
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            let saveResult = await this.saveInfo()
            console.log(saveResult)
            if (!saveResult) {
                loading.close()
                return
            }
            // 重新保存酒店出行人，当前有酒店的情况（当前默认只能订一间酒店）
            /*if (this.hotelList.length > 0) {
                let params = {
                    checkInPerson: [],
                    orderNo: ''
                }
                this.evectionParams.travelUserList.forEach(value => {
                    params.checkInPerson.push(value.userId)
                })
                params.orderNo = this.hotelList[0].orderNo
                let [hotelErr, hotelRes] = await awaitWrap(consumer_tOdHotelOrderInterim_updateCheckInPerson(params))
                if (hotelErr) {
                    loading.close()
                    return
                }

            }*/

            /*if (this.evectionInfo.evectionType === 2) {
                // 出差单出差
                this.dialogCreateOrderVisible = true
                return
            }*/
            let [err, result] = await awaitWrap(consumer_journey_unityCreate({evectionNo: this.evectionNo, pageType: 1}))
            if (err) {
                loading.close()
                return
            }
            let orderResult = result.datas
            let canSubmit = true
            orderResult.orderResultList.forEach(value => {
                if (!value.isSuccess) {
                    canSubmit = false
                    let [...trainList] = this.trainList
                    let [...flightList] = this.flightList
                    let [...hotelList] = this.hotelList
                    trainList.forEach(value1 => {
                        if (value1.orderNo === value.orderNo) {
                            value1.showError = true
                            value1.errorReason = value.desc
                        }
                    })
                    flightList.forEach(value1 => {
                        if (value1.journeyNo === value.orderNo) {
                            value1.showError = true
                            value1.errorReason = value.desc
                        }
                    })
                    hotelList.forEach(value1 => {
                        if (value1.orderNo === value.orderNo) {
                            value1.showError = true
                            value1.errorReason = value.desc
                        }
                    })
                    this.trainList = trainList
                    this.flightList = flightList
                    this.hotelList = hotelList
                }
            })
            loading.close()
            if (canSubmit) {
                if (this.evectionInfo.evectionType === 2) {
                    this.resultMsg = result.msg
                    this.isHaveOverproof = orderResult.isHaveOverproof
                    this.dialogCreateOrderVisible = true
                } else {
                    this.$router.push({
                        name: 'admin-travel-payment',
                        query: {
                            evectionNo: this.evectionNo
                        }
                    })
                }
            } else {
                this.$message({
                    type: 'warning',
                    message: '订票失败，请重新选择行程'
                })
            }
        },
        // 检查表单
        checkForm() {
            return new Promise(resolve => {
                this.$refs['orderForm'].validate((valid) => {
                    resolve(valid)
                })
            })
        },
        // 保存并重新获取价格
        async saveAndReactPrice() {
            await this.saveInfo()
            await this.getAllPrice()
        },
        submitEnd() {
            this.dialogCreateOrderVisible = false
            this.$router.push({
                name: 'admin-my-order-flight-orderList'
            })
        },
        async submitCreateOrder() {
            let [err, result] = await awaitWrap(consumer_journey_unityCreate({evectionNo: this.evectionNo}))
            if (err) {
                return
            }
            let orderResult = result.datas
            let canSubmit = true
            orderResult.forEach(value => {
                if (!value.isSuccess) {
                    canSubmit = false
                    let [...trainList] = this.trainList
                    trainList.forEach(value1 => {
                        if (value1.orderNo === value.orderNo) {
                            value1.showError = true
                            value1.errorReason = value.desc
                        }
                    })
                    this.trainList = trainList
                }
            })
            if (canSubmit) {
                this.$router.push({
                    name: 'admin-payment'
                })
            } else {
                this.$message({
                    type: 'warning',
                    message: '订票失败，请重新选择行程'
                })
            }
        },

        showThisRuleList(fIndex, index, val) {
            console.log(index, val)
            let orangeFlightList = JSON.parse(JSON.stringify(this.flightList))
            orangeFlightList[fIndex].segmentList[index].loading = true
            this.flightList = orangeFlightList
            let params = {
                cabinCode: val.cabinCode,
                flightNo: val.flightNo
            }
            consumer_air_ticket_flightRules(params).then(res => {
                let result = res.datas.rsData
                // 退票规则
                let ruleList = []
                let ruleItem = {}
                let ruleTitList = [] // 表格title
                if (result.refRule === null) {
                    ruleTitList = [
                        ' ',
                        '统一起飞前/后'
                    ]
                    ruleList = [
                        {
                            tips0: '退',
                            tips1: '依据产品规则执行'
                        },
                        {
                            tips0: '改',
                            tips1: '依据产品规则执行'
                        }
                    ]
                } else {
                    let refRule = result.refRule.rules
                    let chaRule = result.chaRule.rules
                    let refRuleTitArr = []
                    let chaRuleTitArr = []
                    let refRuleArr = []
                    let chaRuleArr = []
                    refRule.forEach(value => {
                        value.type = "refRule"
                        if (refRuleTitArr.indexOf(value.ruleTime) === -1) {
                            refRuleTitArr.push(value.ruleTime)
                            refRuleArr.push(value)
                        }
                    })
                    chaRule.forEach(value => {
                        value.type = "chaRule"
                        if (chaRuleTitArr.indexOf(value.ruleTime) === -1) {
                            chaRuleTitArr.push(value.ruleTime)
                            chaRuleArr.push(value)
                        }
                    })
                    refRuleArr.sort((a, b) => {
                        return a.serial - b.serial
                    })
                    chaRuleArr.sort((a, b) => {
                        return a.serial - b.serial
                    })
                    refRuleArr.forEach((val, index) => {
                        if (index === 0) {
                            ruleTitList.push(' ')
                            ruleItem = {
                                tips0: '退票'
                            }
                        }
                        ruleItem[`tips${index + 1}`] = val.rateText
                        if (val.timeUnit === 1 && val.ruleTime.indexOf('>') >= 0) {
                            let result = val.ruleTime.substr(1)
                            let tips = `起飞前${parseInt(result) / 24}天（含）外`
                            ruleTitList.push(tips)
                        }
                        if (val.timeUnit === 1 && val.ruleTime.indexOf('-') >= 0) {
                            let resultArr = val.ruleTime.split('-')
                            let tips = `起飞前${parseInt(resultArr[0]) > 48 ? (parseInt(resultArr[0]) / 24) : parseInt(resultArr[0])}${parseInt(resultArr[0]) > 48 ? '天' : '小时'}（含）外至${parseInt(resultArr[1]) > 48 ? (parseInt(resultArr[1]) / 24) : parseInt(resultArr[1])}${parseInt(resultArr[1]) > 48 ? '天' : '小时'}（不含）外`
                            ruleTitList.push(tips)
                        }
                        if (val.ruleTime.indexOf('-') < 0 && val.ruleTime.indexOf('>') < 0) {
                            ruleTitList.push(val.ruleTime)
                        }
                    })
                    ruleList.push(ruleItem)
                    chaRuleArr.forEach((val, index) => {
                        if (index === 0) {
                            ruleItem = {
                                tips0: '改签'
                            }
                        }
                        ruleItem[`tips${index + 1}`] = val.rateText
                    })
                    ruleList.push(ruleItem)
                }

                let flightList = JSON.parse(JSON.stringify(this.flightList))
                flightList[fIndex].segmentList[index].ruleList = ruleList;
                flightList[fIndex].segmentList[index].ruleTitList = ruleTitList;
                // this.flightList[fIndex].segmentList[index].luggage = result.luggage
                flightList[fIndex].segmentList[index].loading = false
                this.flightList = flightList
            })
        },

        showKnowPopup() {
            this.dialogKnowVisible = true
        },
        // 获取常用旅客
        getUsuallyUser() {
            consumer_frequent_contacts_getList({currentPage: 1, pageSize: 100}).then(res => {
                let frequentPassenger = res.pageResult.pageData
                /*frequentPassenger.forEach(value => {
                    if (value.status === 0) {
                        this.frequentPassenger.push(value)
                    }
                })*/
                this.frequentPassenger = frequentPassenger
            })
        },
        // 确定勾选中的常用旅客
        submitPassenger() {
            let activeUser = []
            this.evectionParams.travelUser.forEach((value, index) => {
                if (value.id && value.id !== '') {
                    if (this.storageActiveFrequentPassenger.indexOf(value.id) === -1) { // 勾选中去掉了
                        // 删除该项
                        this.evectionParams.travelUser.splice(index, 1)
                    } else {
                        activeUser.push(value.id)
                    }
                }
            })
            let travelUser = []
            this.storageActiveFrequentPassenger.forEach(value => {
                this.frequentPassenger.forEach(value1 => {
                    if (value === value1.id && activeUser.indexOf(value) === -1) {
                        let isPushed = false
                        for (let i = 0, l = this.evectionParams.travelUser.length; i < l; i++) {
                            let isHaveStation = this.isBlankStation(this.evectionParams.travelUser[i])
                            if (isHaveStation) {
                                isPushed = true
                                this.evectionParams.travelUser.splice(i, 1, {
                                    id: value1.id,
                                    username: value1.username,
                                    phone: value1.phone,
                                    certificateType: value1.certificateType,
                                    certificateValue: value1.certificateValue,

                                    birthday: value1.birthday, // 出生日期
                                    passengerExpireDate: value1.passengerExpireDate, // 证件有效期
                                    englishSurnames: value1.englishSurnames, // 姓氏
                                    englishName: value1.englishName, // 名字（和姓氏配合）
                                    staffSex: value1.staffSex, // 性别

                                    passengerType: value1.passengerType,
                                    nationality: value1.nationality, // 国籍

                                    choice: 0, //是否保存
                                    smsType: value1.smsType,

                                    uid: value1.uid,
                                    cid: value1.cid,
                                    ofcId: value1.ofcId,
                                })
                                break
                            }
                        }
                        if (!isPushed) {
                            this.evectionParams.travelUser.push({
                                id: value1.id,
                                username: value1.username,
                                phone: value1.phone,
                                certificateType: value1.certificateType,
                                certificateValue: value1.certificateValue,

                                birthday: value1.birthday, // 出生日期
                                passengerExpireDate: value1.passengerExpireDate, // 证件有效期
                                englishSurnames: value1.englishSurnames, // 姓氏
                                englishName: value1.englishName, // 名字（和姓氏配合）
                                staffSex: value1.staffSex, // 性别

                                passengerType: value1.passengerType,
                                nationality: value1.nationality, // 国籍

                                choice: 0, //是否保存
                                smsType: 1,

                                uid: value1.uid,
                                cid: value1.cid,
                                ofcId: value1.ofcId,
                            })
                        }
                        /*travelUser.push({
                            id: value1.id,
                            username: value1.username,
                            phone: value1.phone,
                            certificateType: value1.certificateType,
                            certificateValue: value1.certificateValue,

                            birthday: value1.birthday, // 出生日期
                            passengerExpireDate: value1.passengerExpireDate, // 证件有效期
                            englishSurnames: value1.englishSurnames, // 姓氏
                            englishName: value1.englishName, // 名字（和姓氏配合）
                            staffSex: value1.staffSex, // 性别

                            passengerType: value1.passengerType,
                            nationality: value1.nationality, // 国籍

                            choice: 0, //是否保存

                            uid: value1.uid,
                            cid: value1.cid,
                            ofcId: value1.ofcId,
                        })*/
                        /*this.evectionParams.travelUser.push({
                            id: value1.id,
                            username: value1.username,
                            phone: value1.phone,
                            certificateType: value1.certificateType,
                            certificateValue: value1.certificateValue,

                            birthday: value1.birthday, // 出生日期
                            passengerExpireDate: value1.passengerExpireDate, // 证件有效期
                            englishSurnames: value1.englishSurnames, // 姓氏
                            englishName: value1.englishName, // 名字（和姓氏配合）
                            staffSex: value1.staffSex, // 性别

                            passengerType: value1.passengerType,
                            nationality: value1.nationality, // 国籍

                            choice: 0, //是否保存

                            uid: value1.uid,
                            cid: value1.cid,
                            ofcId: value1.ofcId,
                        })*/
                    }
                })
            })
            // this.evectionParams.travelUser = travelUser.concat(this.evectionParams.travelUser)
            this.passengerDialogVisible = false
            this.$refs['orderForm'].clearValidate()
            this.getAllPrice()
        },
        // 是否是一个空白的占位
        isBlankStation(val) {
            if (val.username === '') {
                return true
            }
            if (val.phone === '') {
                return true
            }
            if (val.certificateValue === '') {
                return true
            }
            if (val.certificateType !== 1 && val.passengerExpireDate === '') {
                return true
            }
            if ([1, 2].indexOf(val.certificateType) === -1 && val.birthday === '') {
                return true
            }
            if ([1, 2].indexOf(val.certificateType) === -1 && val.englishSurnames === '') {
                return true
            }
            if ([1, 2].indexOf(val.certificateType) === -1 && val.englishName === '') {
                return true
            }
            if ([1, 2].indexOf(val.certificateType) === -1 && val.staffSex === '') {
                return true
            }
            return false
        },
        cancelPassenger() {
            this.passengerDialogVisible = false
        },

        // 新增用户
        addNewTravelUser() {
            if (this.evectionParams.travelUser.length >= 9) {
                return
            }
            this.evectionParams.travelUser.push({
                id: '',
                username: '',
                phone: '',
                certificateType: 1,
                certificateValue: '',

                birthday: '', // 出生日期
                passengerExpireDate: '', // 证件有效期
                englishSurnames: '', // 姓氏
                englishName: '', // 名字（和姓氏配合）
                staffSex: '', // 性别

                passengerType: '1',
                nationality: '', // 国籍

                choice: 0, //是否保存
                smsType: 1,

                uid: '',
                cid: '',
                ofcId: '',
            })
            this.getAllPrice()
        },
        // 展示常用旅客弹窗
        showChooseUsuallyUser() {

            let activeUser = []
            this.evectionParams.travelUser.forEach(value => {
                if (value.id && value.id !== '') {
                    activeUser.push(value.id)
                }
            })
            let travelUserNum = 0
            this.evectionParams.travelUser.forEach(value => {
                let isBlankStation = this.isBlankStation(value)
                if (!isBlankStation) {
                    travelUserNum++
                }
            })
            this.maxUser = 9 - travelUserNum + activeUser.length
            this.storageActiveFrequentPassenger = activeUser
            this.passengerDialogVisible = true
        },
        //
        delTravelUser(index) {
            this.evectionParams.travelUser.splice(index, 1)
            this.getAllPrice()
        },
        // 确认联系人
        submitContact() {
            if (this.contactId !== '') {
                this.frequentPassenger.forEach(value => {
                    if (value.id === this.contactId) {
                        this.evectionParams.linkman = value.username
                        this.evectionParams.linkmanPhone = value.phone
                    }
                })
            }
            this.contactDialogVisible = false
        },
        // 取消联系人
        cancelContact() {
            this.contactDialogVisible = false
        },
        // 打开联系人选择弹窗
        showContactDialog() {
            this.contactId = ''
            this.contactDialogVisible = true
        },
        getAreaLine() {
            consumer_web_city_areaLinkage({levelType: 1}).then(res => {
                this.nationalityList = res.datas
            })
        },

        async evectionToAddNewJourney(val) {
            let continueToFlight = await this.saveInfo()
            if (!continueToFlight) {
                return
            }
            let queryParams = {}
            const key = 'params';
            if (val.businessType === 1) {
                // 机票
                queryParams = {
                    redirectType: '001',
                    startDate: val.departTime,
                    endDate: val.returnTime,
                    fromStationCode: val.departCityId,
                    fromStationName: val.departCityName,
                    toStationCode: val.toCityId,
                    toStationName: val.toCityName,
                    flightType: 1,
                    journeyId: val.id,
                    applyId: this.evectionInfo.evectionApplyId ? this.evectionInfo.evectionApplyId : ''
                }
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.$router.push({
                    name: 'admin-flight-search',
                    query: {
                        params: encrypted,
                        evectionNo: this.evectionNo,
                        evectionType: this.evectionParams.evectionType,
                    }
                })
            } else if (val.businessType === 3) {
                // 酒店
                queryParams = {
                    redirectType: '001',
                    cityId: val.toCityId,
                    cityName: val.toCityName,
                    checkInDate: val.departTime,
                    checkOutDate: val.returnTime,
                    keyword: '',
                    hotelStar: '',
                    journeyId: val.id
                }
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.$router.push({
                    name: 'admin-hotel-search',
                    query: {
                        params: encrypted,
                        evectionNo: this.evectionNo,
                        evectionType: this.evectionParams.evectionType,

                    }
                })
            } else {
                queryParams = {
                    redirectType: '001',
                    time: val.departTime,
                    fromStationCode: val.departCityId,
                    toStationCode: val.toCityId,
                    onlySearch: 0,
                    journeyId: val.id,
                }
                const encrypted = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key,
                });
                this.$router.push({
                    name: 'admin-train-search',
                    query: {
                        params: encrypted,
                        evectionNo: this.evectionNo,
                        evectionType: this.evectionParams.evectionType,
                    }
                })
            }

        },
        getStopMsg(val, index, segmentIndex) {
            console.log(val, index, segmentIndex)
            let params = {
                "rqData": {
                    "date": val.depDate,
                    "flightNo": val.flightNo
                }
            }
            // val.stopLoad = true
            this.flightList[index].segmentList[segmentIndex].stopLoad = true
            let flightsList = JSON.parse(JSON.stringify(this.flightList))
            consumer_flight_order_flightsStop(params).then(res => {
                flightsList[index].segmentList[segmentIndex].stopData = res.datas
                flightsList[index].segmentList[segmentIndex].stopLoad = false
                this.flightList = flightsList
            }).catch(() => {
                flightsList[index].segmentList[segmentIndex].stopLoad = false
                this.flightList = flightsList
            })
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
